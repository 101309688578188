<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <ByLedLogo></ByLedLogo>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Технические работы
        </h2>
        <p class="mb-2">
          Приносим извинения за временные неудобства, мы проводим технические работы!
        </p>

       <div class="row justify-content-center">
          <div class="col-9 email-block">
            <BlInput placeholder="email@gmail.com"
                     name="email"
                     v-model="this.email"
                     class="col-12"
                     rules="email|required"></BlInput>
          </div>
          <div class="col-3">
            <b-button
                variant="primary"
                class="btn-sm-block pb-1 m-0"
            >
              Уведомить
            </b-button>
          </div>
       </div>

        <!--Баннер-->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store'
import ByLedLogo from "@/components/core/ByLedLogo";
import BlInput from "@/components/controls/BlInput";
import '@/libs/custom-validate'

export default {
  components: {
    BlInput,
    ByLedLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      email: ''
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.email-block{


}
</style>
